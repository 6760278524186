@tailwind components;

@layer components {
  .slider-wrapper {
    @apply absolute rounded-md origin-top-left h-10 p-2 transform -rotate-90 bottom-2 bg-gray-500 flex items-center;
  }

  .range-slider {
    -webkit-appearance: none;
    @apply bg-gray-600 h-1 rounded-md;
  }

  .range-slider:focus {
    outline: none;
  }

  .range-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    @apply w-4 h-4 rounded-full cursor-pointer bg-gray-600;
  }
}
