@tailwind components;

@layer components {
  .btn {
    @apply font-sans font-semibold inline-flex items-center justify-center rounded-md py-2 px-2 text-lg;

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: 0;
      box-shadow: 0 0 0 4px var(--btn-color-focus);
    }
  }

  .btn--outline {
    @apply border border-gray-300;
  }

  .btn--default {
    @apply border border-gray-600;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(205, 205, 205, 1) 100%
    );
  }

  .btn--primary {
  }

  [disabled] {
    @apply text-gray-500 border-gray-300;
    background: gray !important;
  }
}
