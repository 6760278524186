@tailwind components;

@layer components {
  #BLremoteVideoContainer video {
    @apply absolute right-0 bottom-0 w-full h-full;
    @apply object-cover;
  }

  #remoteVideoContainer video {
    @apply absolute right-0 bottom-0 w-full h-full;
    @apply object-cover;
  }

  #BLlocalVideoContainer video {
    top: unset;
    left: unset;
    transform: unset;
  }

  #localVideoContainer video {
    top: unset;
    left: unset;
    transform: unset;
  }
}
