button:focus {
  /* outline-width: 0 !important; */
  /* box-shadow: 0 0 0 2px theme('colors.yellow.200'); */
}

div[role='menuitem']:focus {
  border-radius: 5px;
  box-shadow: 0 0 0 2px theme('colors.blue.500');
}

/* div[role='button']:focus, */
/* .btn:focus {
  outline-color: #fff700 !important;
  outline-style: solid;
  outline-width: 3px;
} */

.chakra-select__icon-wrapper:dir(ltr) {
  right: 0.2rem !important;
}
.chakra-select__icon-wrapper:dir(rtl) {
  right: auto;
  left: 0.5rem;
}
.custom-select .chakra-select__wrapper {
  width: fit-content !important;
}

.vitatalk-player {
  /* min-height: calc(100vh - 40px) !important;
  max-height: calc(100vh - 40px) !important; */
  overflow: hidden !important;
  background-color: black;
}

.min-vitatalk-player {
  overflow: hidden !important;
}

/* video control slider style update */
.media-progress .rc-slider {
  height: 20px !important;
  padding: 0 !important;
}
.media-progress .rc-slider-rail {
  background: transparent !important;
  border: 1px solid silver !important;
  height: 20px !important;
  border-radius: 2px !important;
  cursor: pointer !important;
}
.media-progress .rc-slider-track {
  background: red !important;
  height: 20px !important;
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
  border: 1px solid silver !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: none !important;
}

.media-progress .rc-slider-handle {
  background: transparent !important;
  border: none !important;
}
.media-progress .rc-slider-handle .rc-slider-handle-content {
  width: 44px !important;
  color: white !important;
}
.media-progress
  .rc-slider-handle
  .rc-slider-handle-content
  .rc-slider-handle-inner {
  margin-left: -15px !important;
}

/* volume controller slider style update */
.volume-control .rc-slider-mark {
  display: none !important;
}
.volume-control .rc-slider-rail {
  background: #504f4f !important;
}
.volume-control .rc-slider-track {
  background: #504f4f !important;
}
.volume-control .rc-slider-handle {
  background: #504f4f !important;
  border-color: #504f4f !important;
}
.volume-control .rc-slider-handle:active {
  box-shadow: none !important;
}
video {
  width: calc(100vw - 192px) !important;
  max-height: calc(100vh - 40px) !important;
  overflow: hidden !important;
  /* height: calc(100vh - 40px) !important; */
}
.min-vitatalk-player video {
  height: 120px !important;
  /* width: auto !important; */
}
.min-vitatalk-player .media-wrapper {
  background-color: black;
}
.vidatalk-audio {
  height: calc(100vh - 40px) !important;
}
.media-loader {
  transform: translate(-50%, -50%);
}
.rotate-45 {
  transform: rotate(45deg);
}
.vjs-loading-spinner {
  display: none;
}
.vjs-big-play-button {
  display: none;
  /* margin-top: 60px;
  color: white; */
}
.vjs-text-track-settings {
  display: none;
}
.vjs-error-display {
  display: none;
}
.media-wrapper {
  /* height: 100vh; */
  height: calc(100vh - 140px);
}
.vjs-control-bar {
  display: none;
}
.minimized #vitatalk_player {
  display: flex !important;
  width: 200px !important;
  height: 120px !important;
  flex: 1 !important;
  flex-direction: column !important;
  position: relative !important;
  padding-top: 0 !important;
}
#vitatalk_player {
  display: flex !important;
  width: 100% !important;
  flex: 1 !important;
  flex-direction: column !important;
  position: relative !important;
  padding-top: 0 !important;
}
/* .vitatalk_player-mini {
  width: 200px !important;
  height: 120px !important;
  position: relative !important;
} */
video {
  width: 100vw !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
::-webkit-media-controls {
  display: none !important;
}
video::-webkit-media-controls {
  display: none !important;
}
video::-webkit-media-controls-enclosure {
  display: none !important;
}
#vitatalk_player_html5_api .media-controls-container {
  display: none !important;
}
.media-controls-container .media-controls .play-pause {
  display: none !important;
}
.play-pause {
  display: none !important;
}
video button {
  display: none !important;
}
.vjs-big-play-button {
  display: none !important;
}
.App {
  height: 100vh !important;
}
.video-js .vjs-tech {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
}
.vjs-waiting {
  visibility: hidden;
  background: transparent;
}
.vjs-loading-spinner {
  display: none !important;
}

.vidatalk-audio {
  height: 100%;
}
