@import './theme/components/button.css';
@import './theme/components/range_slider.css';
@import './theme/utilities/layout.css';
@import './theme/components/card.css';
@import './theme/components/boostLingo.css';
@import './theme/components/pinForm.css';
@import './theme/components/media.css';
@import './theme/overrides.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
  font-size: 16px;
  height: -webkit-fill-available;
}

/* https://dev.to/colingourlay/how-to-support-apple-s-dynamic-text-in-your-web-content-with-css-40c0 */
/* @supports (font: -apple-system-body) {
  @media (max-width: 768px) {
    html {
      font: -apple-system-body !important;
    }
  }
} */

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  /* @apply bg-gray-300 dark:bg-gray-600; */
  /* @apply bg-blue-25 dark:bg-gray-900; */
  @apply bg-blue-300 !important; /* this should match index.html */
  /* background-color: #ebf0f5 !important; */
}

html,
body,
div#root {
  @apply relative flex flex-col;
}

div#root {
  @apply min-h-full text-base;
}

html,
body {
  /* Temporary work around to prevent overscroll with canvas on mobile */
  /* overflow: hidden;
  overscroll-behavior: none; */
}

.AppSidebar a.active {
  @apply bg-gray-300 dark:bg-gray-500;
}

.AppMenu {
}

.AppContent {
  /* overflow: auto;
  overscroll-behavior: auto; */
}
.disable-scrol {
  height: calc(100vh - 40px);
  overflow: hidden;
}

html[dir='ltr'] .pain-scale-selector {
  background: linear-gradient(90deg, #ffffff, #f52403);
}

html[dir='rtl'] .pain-scale-selector {
  background: linear-gradient(90deg, #f52403, #ffffff);
}
