@tailwind utilities;
@layer utilities {
  .t-safe {
    top: env(safe-area-inset-top);
  }
  .t-safe-10 {
    top: calc(2.5rem + env(safe-area-inset-top));
  }
  .r-safe {
    right: env(safe-area-inset-right);
  }
  .l-safe {
    left: env(safe-area-inset-left);
  }
  .b-safe {
    bottom: env(safe-area-inset-bottom);
  }

  /* safe padding */
  .p-safe {
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
  .py-safe {
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
  }
  .px-safe {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
  .pt-safe {
    padding-top: env(safe-area-inset-top);
  }
  .pb-safe {
    padding-bottom: env(safe-area-inset-bottom);
  }
  .pr-safe {
    padding-right: env(safe-area-inset-right);
  }
  .pl-safe {
    padding-left: env(safe-area-inset-left);
  }

  /* safe margin */
  .m-safe {
    margin-top: env(safe-area-inset-top);
    margin-bottom: env(safe-area-inset-bottom);
    margin-left: env(safe-area-inset-left);
    margin-right: env(safe-area-inset-right);
  }
  .my-safe {
    margin-top: env(safe-area-inset-top);
    margin-bottom: env(safe-area-inset-bottom);
  }
  .mx-safe {
    margin-left: env(safe-area-inset-left);
    margin-right: env(safe-area-inset-right);
  }
  .mt-safe {
    margin-top: env(safe-area-inset-top);
  }
  .mb-safe {
    margin-bottom: env(safe-area-inset-bottom);
  }
  .mr-safe {
    margin-right: env(safe-area-inset-right);
  }
  .ml-safe {
    margin-left: env(safe-area-inset-left);
  }
}
